import XLSX from 'xlsx';
import moment from 'moment';
export const exportarArch = (listaExcel,listNom) => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+listNom+".xlsx")
};